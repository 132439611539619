import { type ArticleCard_articleBody } from '~/v1/_types/ArticleCard';
import { ArticleType } from '~/v1/_types/globalTypes';
import { ContentType } from '~/v1/constants/contentType';
import { Route } from '~/v1/constants/route';
import { isInternalLink } from '~/v1/utils/isInternalLink';

import { type ArticleCardType, type RelatedArticle } from './article.interface';

export const getArticleCTA = ({
  type,
  slug,
  articleBody,
}: {
  type: ArticleType;
  slug: string;
  articleBody?: ArticleCard_articleBody | null;
}): { ctaText: string; ctaUrl: string } => {
  const hasVideo = articleBody?.includedEntries.find(
    ({ data }) => data?.__typename === ContentType.SPOTLIGHT_VIDEO,
  );

  switch (type) {
    case ArticleType.GENERIC:
      return {
        ctaText: 'Read the article',
        ctaUrl: `${Route.ARTICLE}/${slug}`,
      };

    case ArticleType.GRANTEE:
    case ArticleType.MELLON_IN_THE_NEWS:
      return {
        ctaText: 'Read the news',
        ctaUrl: `${Route.NEWS}/${slug}`,
      };

    case ArticleType.VOICES:
      return {
        ctaText: 'Read the essay',
        ctaUrl: `${Route.VOICES}/${slug}`,
      };

    case ArticleType.PAST_EVENT:
      return {
        ctaText: hasVideo ? 'Watch event' : 'See event',
        ctaUrl: `${Route.EVENTS}/${slug}`,
      };

    case ArticleType.REPORT:
      return {
        ctaText: 'See the report',
        ctaUrl: `${Route.REPORT}/${slug}`,
      };
  }
};

export const mapArticleCard = (card: RelatedArticle): ArticleCardType => {
  switch (card.__typename) {
    case ContentType.GRANT_ACTIVITY_ARTICLE:
    case ContentType.NESTED_GRANT_ACTIVITY_ARTICLE:
      return {
        eyebrow: card.granteeNameOverride ?? card.grant.granteeName,
        title: card.title,
        image: card.leadImage,
        ctaText: 'Read the grant story',
        ctaUrl: `${Route.GRANT_STORY}/${card.slug}`,
        isExternal: false,
      };

    case ContentType.ARTICLE:
    case ContentType.NESTED_ARTICLE:
      return {
        eyebrow: card.eyebrow,
        title: card.title,
        image: card.articleHeroImage,
        logo:
          !card.articleHeroImage && card.type === ArticleType.GENERIC
            ? {
                src: '/icons/logo-news.svg',
                alt: 'Logo Mellon',
              }
            : undefined,
        isExternal: false,
        isEvent: card.type === ArticleType.PAST_EVENT,
        ...getArticleCTA(card),
      };

    case ContentType.EXTERNAL_EVENT:
      return {
        eyebrow: 'Event',
        image: card.logo,
        title: card.name,
        ctaText: 'See details',
        ctaUrl: card.link,
        isExternal: !isInternalLink(card.link),
      };

    case ContentType.IDEA:
    case ContentType.NESTED_IDEA:
      return {
        eyebrow: card.name,
        image: card.heroImage,
        title: card.question,
        ctaText: 'Explore the Idea',
        ctaUrl: `${Route.IDEA}/${card.name}`,
        isExternal: false,
      };

    case ContentType.ANNUAL_REPORT:
    case ContentType.NESTED_ANNUAL_REPORT:
      return {
        eyebrow: 'Report',
        image: card.introImages[0],
        title: `${card.year} Annual Report`,
        ctaText: `Read the President’s Letter`,
        ctaUrl: `${Route.ANNUAL_REPORT}/${card.slug}`,
      };

    default:
      return {
        eyebrow: null,
        image: null,
        title: '',
        isExternal: false,
        ctaText: 'DEFAULT',
        ctaUrl: '',
      };
  }
};
