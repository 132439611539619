export enum AudioStatus {
  LOADING,
  ERROR,
  LOADED,
  PLAYING,
  PAUSED,
}

export const AUDIO_PLAYER_ARIA_LABEL = {
  [AudioStatus.LOADING]: 'loading',
  [AudioStatus.ERROR]: 'reload',
  [AudioStatus.LOADED]: 'play',
  [AudioStatus.PLAYING]: 'pause',
  [AudioStatus.PAUSED]: 'play',
};
