export function convertTimeToMMSS(time: number): string {
  const timeInMilliseconds = Math.trunc(time) * 1000;
  const timeInHHMMSS = new Date(timeInMilliseconds).toISOString().slice(11, 19);
  const timeParts = timeInHHMMSS.split(':');
  const hours = timeParts[0];
  if (Number(hours) == 0) {
    return timeInHHMMSS.slice(3);
  }
  const minutes = timeParts[1];
  const seconds = timeParts[2];
  return `${Number(hours) * 60 + Number(minutes)}:${seconds}`;
}
