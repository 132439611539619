import { Fragment } from 'react';

export function withLineBreaks(text?: string) {
  const segments = text?.split('\n') ?? [];
  return segments.map((segment, index) => (
    <Fragment key={index}>
      {segment}
      {index < segments.length - 1 && <br />}
    </Fragment>
  ));
}
