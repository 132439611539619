import { type ReactNode, useEffect, useState } from 'react';

interface LayoutClientProps {
  children: ReactNode | null;
}

export const LayoutClient: React.FC<LayoutClientProps> = ({ children }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? <>{children}</> : null;
};
