export enum ContentType {
  ANNUAL_REPORT = 'AnnualReport',
  ARTICLE = 'Article',
  CONNECT_WITH_LINK = 'ConnectWithLink',
  EMPHASIZED_ARTICLE = 'EmphasizedArticle',
  EVENTS = 'Events',
  EXTERNAL_EVENT = 'ExternalEvent',
  FEATURE = 'Feature',
  GRANT_ACTIVITY_ARTICLE = 'GrantActivityArticle',
  IDEA = 'Idea',
  INITIATIVE_QUOTE_CALL_OUT = 'InitiativeQuoteCallOut',
  INLINE_CARDS = 'InlineCards',
  INLINE_IMAGES = 'InlineImages',
  MEDIA = 'Media',
  MMUF = 'MMUF',
  NATIVE_VIDEO = 'NativeVideo',
  NESTED_ANNUAL_REPORT = 'NestedAnnualReport',
  NESTED_ARTICLE = 'NestedArticle',
  NESTED_GRANT_ACTIVITY_ARTICLE = 'NestedGrantActivityArticle',
  NESTED_IDEA = 'NestedIdea',
  OLD_ANNUAL_REPORT = 'OldAnnualReport',
  QUICK_LINK = 'QuickLink',
  QUOTE = 'Quote',
  SPOTLIGHT_SHOW = 'SpotlightShow',
  SPOTLIGHT_VIDEO = 'SpotlightVideo',
  YOUTUBE_VIDEO = 'YouTubeVideo',
  EXPERIENCE_BLOCK = 'ExperienceBlock',
  PARALLAX_IMAGES = 'ParallaxImages',
}
